<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('course_approval_check')"
                        :isFilter="false"/>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('course_approval_check')"
                              :isFilter="false"/>
            </template>
            <div class="row mb-4">
                <div class="w-100 d-none d-lg-block"></div>
                <div class="col-12 col-lg-3 col-xxl-3">

                    <b-input-group size="lg" class="mb-2">

                        <b-input-group-prepend is-text>
                            <i class="ri-search-line"></i>
                        </b-input-group-prepend>
                        <b-form-input
                            type="search"
                            :placeholder="$t('search')"
                            @input="searchStudent"
                        ></b-form-input>
                    </b-input-group>

                    <div class="border p-4 rounded overflow-y-scroll overflow-x-hidden w-100"
                         style="height: calc(100vh - 220px)"
                    >
                        <div v-if="advisor.length>0">
                            <div v-for="(item, key) in advisor" v-bind:key="key">
                                <b-button
                                    :variant="
                          (current_student === item.user_id
                        ? 'outline-primary'
                        : 'outline-secondary text-dark') +
                      ' btn-block justify-content-start highhonor-auto font-weight-medium line-height-normal py-2 text-left mb-2 flex-column align-items-start h-auto'
                    "
                                    @click="getStudent(advisor_id=item.user_id)"
                                >
                                    <span>{{ toUpperCase(item.name + "  " + item.surname) }}</span>
                                    <span>{{ $t('number_of_students') }} : {{ item.number_of_students }}</span>
                                    <span>{{ $t('approved_students') }} : {{ item.approved_students }}</span>
                                    <span>{{ $t('declined_students') }} : {{ item.declined_students }}</span>
                                    <span>{{ $t('pending_students') }} : {{ item.pending_students }}</span>
                                </b-button>
                            </div>
                        </div>
                        <div v-else>
                            <div class="spinner-border text-light mx-auto d-flex"></div>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-lg-9 col-xxl-9">
                    <div class="border p-4 rounded overflow-y-scroll overflow-x-hidden w-100"
                         v-if="showStudent == true">
                        <div>
                            <datatable-filter
                                v-show="true"
                                @filter="getStudent"
                                @filterClear="filterClear"
                            >
                                <b-row>
                                    <b-col sm="6">
                                        <b-form-group :label="$t('name')">

                                        <b-form-input v-model="datatable.queryParams.filter.name"
                                                      :placeholder="$t('name')"
                                        />
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                        <b-form-group :label="$t('surname')">

                                        <b-form-input v-model="datatable.queryParams.filter.surname"
                                                      :placeholder="$t('surname')"
                                        />
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                        <b-form-group :label="$t('faculty_code')">
                                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"
                                            />
                                        </b-form-group>
                                    </b-col>
<!--                                    <b-col sm="6">-->
<!--                                        <b-form-group :label="$t('departman')">-->
<!--                                            <department-selectbox v-model="datatable.queryParams.filter.department_code"-->
<!--                                                                  :faculty_code="datatable.queryParams.filter.faculty_code"-->
<!--                                            />-->
<!--                                        </b-form-group>-->
<!--                                    </b-col>-->
                                    <b-col sm="6">
                                        <b-form-group :label="$t('program')">
                                            <program-selectbox v-model="datatable.queryParams.filter.program_code"
                                                               :faculty_code="datatable.queryParams.filter.faculty_code"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                        <b-form-group :label="$t('student_number')">
                                            <b-form-input
                                                v-model="datatable.queryParams.filter.student_number"
                                            >
                                            </b-form-input>
                                        </b-form-group>
                                    </b-col>

                                    <b-col sm="6">
                                        <b-form-group :label="$t('status')">
                                            <parameter-selectbox code="advisor_approval_statuses"
                                                                 v-model="datatable.queryParams.filter.approval_status"
                                            ></parameter-selectbox>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </datatable-filter>
                            <b-table striped hover
                                     :show-empty="true"
                                     bordered
                                     class="mb-0 table-dropdown no-scrollbar border rounded"
                                     responsive :items="items"
                                     :fields="slotFields"
                            >
                                <template #cell(approval_status)="row">
                                    <span class="badge badge-warning" v-if="row.item.approval_status==='not_sent'">{{ $t('not_sent') }}</span>
                                    <span class="badge badge-danger" v-if="row.item.approval_status==='declined'">{{ $t('declined') }}</span>
                                    <span class="badge badge-success" v-if="row.item.approval_status==='approved'">{{ $t('approved') }}</span>
                                    <span class="badge badge-info" v-if="row.item.approval_status==='pending'">{{ $t('pending') }}</span>
                                </template>
                                <template #cell(action)="row">
                                 <b-button variant="primary" @click="goToRegistration(row.item)"> Control</b-button>
                                </template>
                            </b-table>
                        </div>
                    </div>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
// Services
import StudentDocumentService from "@/services/StudentDocumentService";
import AdvisorApprovals from "@/services/AdvisorApprovals";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox.vue";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox.vue";
import qs from "qs";

export default {
    components: {
        ProgramSelectbox,
        DepartmentSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
    },
    metaInfo() {
        return {
            title: this.$t("course_approval_check"),
        };
    },
    data() {
        return {
            isStudents: true,
            advisor_id: null,
            students: [],
            advisor: [],
            royalAdvisor: [],
            royalStudent:[],
            current_student: null,
            current_student_data: {},
            showStudent: false,
            datatable: {
                showTable: false,
                isLoading: false,
                queryParams: {
                    filter: {
                        name: null,
                        surname: null,
                        program_code: null,
                        advisor_id: null,
                        approval_status: 'pending',
                        department_code: null
                    },
                    page: 1,
                    limit: -1
                },
                rows: [],
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("show_course"),
                                class: "ri-edit-box-line",
                                permission: "menu_update",
                                show: (row) => {
                                    return row.approval_status == 'pending' ? true : false
                                }
                            },
                        ],
                    },
                    {
                        label: this.$t('student_number').toUpper(),
                        field: 'student_number',
                        sortable: false,
                        hidden: false,
                        tdClass: 'align-middle'
                    },
                    {
                        label: this.$t('name').toUpper(),
                        field: 'name',
                        sortable: false,
                        hidden: false,
                        tdClass: 'align-middle'
                    },
                    {
                        label: this.$t('surname').toUpper(),
                        field: 'surname',
                        sortable: false,
                        hidden: false,
                        tdClass: 'align-middle'
                    },
                    {
                        label: this.$t('faculty').toUpper(),
                        field: 'faculty_name',
                        sortable: false,
                        hidden: false,
                        tdClass: 'align-middle'
                    },
                    {
                        label: this.$t('program').toUpper(),
                        field: 'program_name',
                        sortable: false,
                        hidden: false,
                        tdClass: 'align-middle'
                    },
                    {
                        label: this.$t('status').toUpper(),
                        field: 'approval_status',
                        sortable: false,
                        hidden: false,
                        tdClass: 'align-middle',
                        formatFn: (row) => {
                            if (row === 'not_sent') {
                                return this.$t('not_sent')
                            } else if (row === 'approved') {
                                return this.$t('approved')

                            } else {
                                return this.$t('pending')

                            }

                        }
                    },

                ]
            },
            items:[],
            slotFields: [
                {
                    label: this.$t('student_number').toUpper(),
                    key: 'student_number',
                    sortable: false,
                    hidden: false,
                    tdClass: 'align-middle'
                },
                {
                    label: this.$t('name').toUpper(),
                    key: 'name',
                    sortable: false,
                    hidden: false,
                    tdClass: 'align-middle'
                },
                {
                    label: this.$t('surname').toUpper(),
                    key: 'surname',
                    sortable: false,
                    hidden: false,
                    tdClass: 'align-middle'
                },
                {
                    label: this.$t('faculty').toUpper(),
                    key: 'faculty_name',
                    sortable: false,
                    hidden: false,
                    tdClass: 'align-middle'
                },
                {
                    label: this.$t('program').toUpper(),
                    key: 'program_name',
                    sortable: false,
                    hidden: false,
                    tdClass: 'align-middle'
                },
                {
                    label: this.$t('status').toUpper(),
                    key: 'approval_status',
                    sortable: false,
                    hidden: false,
                    tdClass: 'align-middle',
                },
                {
                    label: this.$t('action').toUpper(),
                    key: 'action',
                    sortable: false,
                    hidden: false,
                    tdClass: 'align-middle',
                },
            ]
        };
    },
    watch: {
        advisor_id: {
            handler(newValue) {
                this.advisor_id = newValue
            }
        },

    },
    computed: {},
    methods: {
        filterClear() {
            this.datatable.queryParams.filter = {
                faculty_code:null,
                program_code: null,
                approval_status: null,
                department_code: null
            }
            this.getStudent()
        },
        turkishStringToUpperCase(string) {
            let letters = {i: "İ", ş: "Ş", ğ: "Ğ", ü: "Ü", ö: "Ö", ç: "Ç", ı: "I"};
            string = string.replace(/(([iışğüçö]))/g, function (letter) {
                return letters[letter];
            });
            return string.toUpperCase();
        },
        searchStudent(key) {
            key = this.turkishStringToUpperCase(key);
            if(key.length>2){
             const filteredArray =this.advisor.filter((item)=>{
                    const upperCaseName=this.turkishStringToUpperCase(item.name)
                    const upperCaseSurname=this.turkishStringToUpperCase(item.surname)
                    return (
                        upperCaseName.includes(key)|| upperCaseSurname.includes(key)
                    )
                })
                this.advisor=filteredArray
            }else{
                this.advisor=this.royalAdvisor
            }
        },
        statusFilter(value){
            this.items=this.items.filter((item)=>item.approval_status==value)
        },
        getStudent() {
            this.datatable.queryParams.filter.advisor_id = this.advisor_id
            this.showStudent = true;
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            AdvisorApprovals.getAdvisorStudent(config)
                .then(response => {
                    this.items = response.data.data
                    this.royalStudent=response.data.data
                    if (this.datatable.queryParams.filter.approval_status){
                        this.statusFilter(this.datatable.queryParams.filter.approval_status)
                    }
                }).catch(err => {
                this.showErrors(err)
            })
        },
        async getAdvisor() {
            AdvisorApprovals.getAdvisor()
                .then((response) => {
                    this.royalAdvisor=response.data.data
                    this.advisor = response.data.data
                    if(this.royalAdvisor.length==1){
                        this.advisor_id=this.royalAdvisor[0].user_id;
                        this.getStudent();
                    }
                }).catch(err => {
                this.showErrors(err)
            });
        },
        downloadTranscript(row) {
            if (this.checkPermission('studentdocument_transcript')) {
                StudentDocumentService.downloadTranscript(row.student_program_id, this.$i18n.locale)
                    .then(response => {
                        this._downloadFile(response, row.student_number + '-' + this.$t('transcript') + '.pdf')
                    })
            } else {
                this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
            }
        },
        goToRegistration(data){
            this.$router.push('/course/registration/'+data.student_program_id)
        },
    },

    created() {
        this.getAdvisor();
    },
    mounted() {
    }
}
</script>
<style scoped>
</style>
