import Api from '@/services/Index';

const getAdvisor = async () => {
    return Api.get('/advisor/list');
}
const getAdvisorStudent = async (config) => {
    return Api.get('/advisor/students',config);
}

const store = async (data) => {
    return Api.post('/advisor/approvals', data);
}

const update = async (id, data) => {
    return Api.put('/advisor/approvals/'+id, data);
}

const updateStatus = async (id, data) => {
    return Api.put('/advisor/approvals/'+id+'/status', data);
}

const updateStatusForAdmin = async (id, data) => {
    return Api.put('/advisor/approvals/'+id+'/status/for-admin', data);
}

export default {
    getAdvisor,
    getAdvisorStudent,
    store,
    update,
    updateStatus,
    updateStatusForAdmin
}
